import { lazy } from 'react';
const confbooking = lazy(() => import('./confbooking'));

const confbookingRoutes = [
  {
    path: '/confbooking',
    component: confbooking
  }
];

export default confbookingRoutes;
