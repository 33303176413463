import { lazy } from "react";
const horizontalCutterV2 = lazy(() => import("./horizontalCutterV2"));

const horizontalCutterV2Routes = [

    {
        path: '/horizontalCutterV2',
        component: horizontalCutterV2
    }
];

export default horizontalCutterV2Routes;