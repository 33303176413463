import { lazy } from "react";
const calnew = lazy(() => import("./calnew"));

const calnewRoutes = [
    {
        path: '/calnew',
        component: calnew
    }
];

export default calnewRoutes;