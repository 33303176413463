import { lazy } from "react";
const HrPolicies = lazy(() => import("./HrPolicies"));

const HrPoliciesRoutes = [
    {
        path: "/hrpolicies",
        component: HrPolicies,
    }
];

export default HrPoliciesRoutes;