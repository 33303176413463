import { lazy } from 'react';
const dashProd = lazy(() => import('./dashProd'));

const dashProdRoutes = [
  {
    path: '/dashprod',
    component: dashProd
  }
];

export default dashProdRoutes;
