import { lazy } from 'react';
const auditFileView = lazy(() => import('./auditFileView'));

const auditFileViewRoutes = [
  {
    path: '/auditFileView',
    component: auditFileView
  }
];

export default auditFileViewRoutes;
