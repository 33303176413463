import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import awsExports from './aws-exports';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { registerLicense } from '@syncfusion/ej2-base';
registerLicense(`Ngo9BigBOggjHTQxAR8/V1NMaF5cXmBCfEx0RXxbf1x1ZFRGallTTnNcUj0eQnxTdEBjWH1fcnZQRGVZU0F2XA==`);

Amplify.configure(awsExports);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
