import { lazy } from 'react';
const auditFileUpload = lazy(() => import('./auditFileUpload'));

const auditFileUploadRoutes = [
  {
    path: '/auditFileUpload',
    component: auditFileUpload
  }
];

export default auditFileUploadRoutes;
