import { lazy } from 'react';

const FacDocTask = lazy(() => import('./FacDocTask'));

const FacDocTaskRoutes = [
  {
    path: '/facdoctask',
    component: FacDocTask
  }
];

export default FacDocTaskRoutes;
