import { lazy } from 'react';
const floorDashboardFab = lazy(() => import('./floorDashboardFab'));

const floorDashboardFabRoutes = [
  {
    path: '/floorDashboardFab',
    component: floorDashboardFab
  }
];

export default floorDashboardFabRoutes;
